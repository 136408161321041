import React, { FC } from "react"
import {
  Paper,
  useTheme,
  makeStyles,
  Typography,
  useMediaQuery,
} from "@material-ui/core"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"

const useStyles = makeStyles(theme => ({
  paper: {
    display: "flex",
    height: 360,
    justifyContent: "center",
    padding: theme.spacing(0, 2),
    [theme.breakpoints.down("xs")]: {
      marginBottom: theme.spacing(6),
      flexDirection: "column-reverse",
      padding: theme.spacing(4, 0),
    },
    marginBottom: theme.spacing(12),
  },
  title: {
    [theme.breakpoints.down("md")]: {
      margin: `0 auto ${theme.spacing(2)}px auto`,
    },
    [theme.breakpoints.down("xs")]: {
      margin: `0 auto ${theme.spacing(1)}px auto`,
      textAlign: "center",
    },
    margin: `0 auto ${theme.spacing(6)}px auto`,
  },
  textWrapper: {
    [theme.breakpoints.down("md")]: {
      margin: `auto ${theme.spacing(5)}px auto ${theme.spacing(5)}px`,
    },
    [theme.breakpoints.down("xs")]: {
      margin: `auto ${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(
        5
      )}px`,
      textAlign: "center",
    },
    flexBasis: "50%",
    margin: `auto 0 auto ${theme.spacing(10)}px`,
  },
  image: {
    flexBasis: "50%",
  },
  paragraph: {
    color: theme.palette.text.secondary,
  },
}))

const defaultImgStyle = {
  maxHeight: "100%",
  maxWidth: "100%",
  height: "unset",
  width: "unset",
  left: "unset",
  right: 0,
  top: "50%",
  transform: "translate(0, -50%)",
}

export const Where: FC = () => {
  const data = useStaticQuery(graphql`
    query {
      desktopImage: file(relativePath: { eq: "home/where/italyMap.png" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mobileImage: file(relativePath: { eq: "home/where/italyMap.png" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  const classes = useStyles()
  const theme = useTheme()

  const sources = [
    data.desktopImage.childImageSharp.fluid,
    {
      ...data.mobileImage.childImageSharp.fluid,
      media: theme.breakpoints.down("sm"),
    },
  ]

  const isMobile = useMediaQuery(theme.breakpoints.down("xs"))

  let imgStyle = defaultImgStyle
  if (isMobile) {
    imgStyle = {
      ...imgStyle,
      left: "50%",
      transform: "translate(-50%, -50%)",
      right: undefined,
    }
  }

  return (
    <Paper className={classes.paper}>
      <Img
        className={classes.image}
        fluid={sources}
        title="dove lavoriamo"
        alt="where-we-work"
        imgStyle={imgStyle}
      />
      <div className={classes.textWrapper}>
        <Typography color="primary" variant="h3" className={classes.title}>
          <b>Nord Ovest</b>
        </Typography>
        <Typography component="p" variant="h5" className={classes.paragraph}>
          Partenza e arrivo in{" "}
          <Typography component="span" color="primary">
            <b>PIEMONTE</b>
          </Typography>{" "}
          e{" "}
          <Typography component="span" color="primary">
            <b>LOMBARDIA</b>
          </Typography>
          .<br />
          Altre regioni del Nord Ovest su richiesta. <br />
          Consegna garantita in{" "}
          <Typography component="span" color="primary">
            <b>24 ORE</b>
          </Typography>
          .
        </Typography>
      </div>
    </Paper>
  )
}
