import React, { FC } from "react"
import { useTheme, makeStyles } from "@material-ui/core"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { InfiniteCarousel } from "./carousel"

const useStyles = makeStyles(theme => ({
  slide: {
    display: "flex",
    height: "70vh",
    [theme.breakpoints.down("sm")]: {
      height: "50vh",
    },
  },
  slideImg: {
    width: "100%",
  },
}))

export const HomeGallery: FC = () => {
  const theme = useTheme()
  const classes = useStyles()
  const data = useStaticQuery(graphql`
    query galleryImages {
      mobileImages: allFile(
        filter: {
          extension: { regex: "/(jpg)|(png)|(tif)|(tiff)|(webp)|(jpeg)/" }
          relativePath: { regex: "/home/gallery/" }
        }
      ) {
        edges {
          node {
            childImageSharp {
              fluid(maxWidth: 960, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      desktopImages: allFile(
        filter: {
          extension: { regex: "/(jpg)|(png)|(tif)|(tiff)|(webp)|(jpeg)/" }
          relativePath: { regex: "/home/gallery/" }
        }
      ) {
        edges {
          node {
            childImageSharp {
              fluid(maxWidth: 1920, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `)

  const sources = data.desktopImages.edges.map(({ node }, i) => {
    const r = [node.childImageSharp.fluid]
    const mobile = data.mobileImages.edges[i]
    if (mobile) {
      r.push({
        ...mobile.node.childImageSharp.fluid,
        media: theme.breakpoints.down("sm"),
      })
    }
    return r
  })

  return (
    <InfiniteCarousel
      arrowPrevStyle={{
        right: "calc(5% + 50px)",
      }}
      arrowNextStyle={{
        right: "5%",
      }}
      showArrows="desktop"
    >
      {sources.map((s, i) => {
        return (
          <div key={`slide${i}`} className={classes.slide}>
            <Img
              key={`img${i}`}
              style={{ objectFit: "cover" }}
              durationFadeIn={50}
              title="vezzaro snc quick food delivery image gallery"
              alt="food delivery"
              fluid={s}
              className={classes.slideImg}
            />
          </div>
        )
      })}
    </InfiniteCarousel>
  )
}
