import React, { FC, useState, useMemo } from "react"
import { useTheme, makeStyles, Paper, Typography } from "@material-ui/core"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { InfiniteCarousel } from "./carousel"
import { useWindowSize } from "../utils/useWindowSize"

const useStyles = makeStyles(theme => ({
  slide: {
    display: "flex",
    justifyContent: "space-evenly",
    padding: theme.spacing(4, 2),
    marginBottom: 80,
  },
  title: {
    margin: `${theme.spacing(6)}px auto ${theme.spacing(3)}px auto`,
    textAlign: "center",
  },
}))

export const PartnerLogoGallery: FC = () => {
  const theme = useTheme()
  const logoWidth = 290
  const logoSpacing = theme.spacing(20)
  const logoHeight = 120

  const classes = useStyles()
  const data = useStaticQuery(graphql`
    query partnerLogoImages {
      allFile(
        filter: {
          extension: { regex: "/(jpg)|(png)|(tif)|(tiff)|(webp)|(jpeg)/" }
          relativePath: { regex: "/home/partner/logo/" }
        }
      ) {
        edges {
          node {
            childImageSharp {
              fixed(width: 290, height: 120, quality: 80) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
            name
          }
        }
      }
    }
  `)

  const [ref, setRef] = useState<HTMLDivElement | null>(null)
  const [width, _] = useWindowSize()

  const slidesSources = useMemo(() => {
    const width = ref?.clientWidth
    const sources = []
    const edges = data.allFile.edges
    if (data && width) {
      const slidesPerRow = Math.round(width / (logoWidth + logoSpacing))
      for (let i = 0; i < Math.ceil(edges.length / slidesPerRow); i++) {
        let slice = edges.slice(
          slidesPerRow * i,
          (slidesPerRow) * (i +1)
        )
        const missingInSlice = slidesPerRow - slice.length
        if (i !== 0 && missingInSlice && missingInSlice < edges.length) {
          slice = slice.concat(edges.slice(0, missingInSlice))
        }
        sources.push(slice)
      }
    }
    return sources
  }, [data, ref, width])

  const onRefChange = (newRef: HTMLDivElement) => setRef(newRef)

  return (
    <Paper id="our-partner" ref={onRefChange}>
      <Typography color="primary" variant="h3" className={classes.title}>
        <b>Si affidano a noi</b>
      </Typography>
      <InfiniteCarousel
        arrowPrevStyle={{
          left: "50%",
          transform: `translate(-50%) translate(-${theme.spacing(1)}px)`,
        }}
        arrowNextStyle={{
          left: "50%",
          transform: `translate(50%) translate(${theme.spacing(1)}px)`,
        }}
        showIndicator={false}
        showArrows="desktop"
      >
        {slidesSources.map((s, i) => {
          return (
            <div key={`slide${i}`} className={classes.slide}>
              {s.map(({ node }, j) => (
                <div
                  style={{ height: logoHeight, width: logoWidth }}
                  key={`slide_logo${i}_${j}`}
                >
                  <Img
                    style={{ objectFit: "cover" }}
                    durationFadeIn={50}
                    title={`logo ${node.name} - vezzaro snc partner`}
                    alt={`${node.name} partner logo`}
                    fixed={node.childImageSharp.fixed}
                  />
                </div>
              ))}
            </div>
          )
        })}
      </InfiniteCarousel>
    </Paper>
  )
}
