import {
  makeStyles,
  Theme,
  createStyles,
  fade,
  TextFieldProps,
  TextField,
  OutlinedInputProps,
  FormControlLabel,
  Checkbox,
  Button,
  Typography,
  Paper,
  Link as MuiLink,
  FormHelperText,
  FormGroup,
} from "@material-ui/core"
import React, { FC, Fragment, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import clsx from "clsx"
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from "react-google-recaptcha-v3"
import { useForm, Controller } from "react-hook-form"
import https from "https"
import { ContactUsDialog } from "./contactUsDialog"
import { SendOutlined } from "@material-ui/icons"

const useStylesMyText = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      border: "1px solid #e2e2e1",
      overflow: "hidden",
      borderRadius: 4,
      backgroundColor: "#fcfcfb",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      "&:hover": {
        backgroundColor: "#fff",
      },
      "&$focused": {
        backgroundColor: "#fff",
        boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
        borderColor: theme.palette.primary.main,
      },
    },
  })
)

const MyTextField = (props: TextFieldProps) => {
  const classes = useStylesMyText()
  return (
    <TextField
      InputProps={
        { classes, disableUnderline: true } as Partial<OutlinedInputProps>
      }
      {...props}
    />
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      display: "flex",
      justifyContent: "center",
      margin: theme.spacing(12, 0),
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        margin: theme.spacing(6, 0),
      },
      [theme.breakpoints.up("md")]: {
        padding: theme.spacing(0, 4),
      },
    },
    contactsPaper: {
      [theme.breakpoints.up("md")]: {
        marginRight: theme.spacing(5),
        marginLeft: "auto",
        padding: theme.spacing(3, 5),
      },
      [theme.breakpoints.down("sm")]: {
        display: "flex",
        justifyContent: "space-evenly",
        flexWrap: "wrap",
      },
      padding: theme.spacing(3),
      background: theme.palette.secondary.main,
      color: theme.palette.text.primary,
    },
    formWrapper: {
      flexWrap: "wrap",
      display: "flex",
      justifyContent: "space-between",
      maxWidth: 840,
      padding: theme.spacing(3, 0),
      margin: "auto",
      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(3, 2),
      },
    },
    input: {
      "@media (max-width: 479.95px)": {
        flexBasis: "100%",
      },
      flexBasis: "45%",
      minWidth: 200,
      marginBottom: theme.spacing(3),
    },
    msgInput: {
      flexBasis: "100%",
    },
    checkBox: {
      margin: theme.spacing(3, 0),
      flexBasis: "100%",
    },
    submitButton: {
      color: theme.palette.getContrastText(theme.palette.primary.main),
      [theme.breakpoints.down("sm")]: {
        margin: "auto",
      },
    },
    formTitle: {
      [theme.breakpoints.down("sm")]: {
        textAlign: "center",
      },
      flexBasis: "100%",
      marginBottom: theme.spacing(3),
    },
    contactsTitle: {
      [theme.breakpoints.down("sm")]: {
        textAlign: "center",
      },
      flexBasis: "100%",
      color: theme.palette.text.primary,
    },
    contactsLink: {
      color: theme.palette.text.primary,
    },
    contactParagraph: {
      [theme.breakpoints.down("sm")]: {
        margin: theme.spacing(2, 3),
        flexBasis: "100%",
        textAlign: "center",
      },
      [theme.breakpoints.up("md")]: {
        marginBottom: theme.spacing(3),
      },
    },
    contactTitle: {
      [theme.breakpoints.down("sm")]: {
        textAlign: "center",
      },
    },
  })
)

interface IFormInput {
  fullname: string
  phone: string
  email: string
  company: string
  message: string
  privacy: boolean
}

const doRequest = (formData: IFormInput & { recaptcha: string }) => {
  return new Promise((resolve, reject) => {
    const data = JSON.stringify(formData)

    const options = {
      hostname: "quick-food-delivery.oa.r.appspot.com",
      port: 443,
      path: "/contact",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Content-Length": data.length,
      },
    }

    const req = https.request(options, res => {
      console.log(`statusCode: ${res.statusCode}`)

      res.on("data", d => {
        console.log(d.toString())
      })
    })

    req.on("finish", () => {
      console.log("DONE")
      resolve()
    })

    req.on("error", error => {
      console.error(error)
      reject(error)
    })

    req.write(data)
    req.end()
  })
}

export const ContactUsForm: FC = () => {
  const data = useStaticQuery(graphql`
    query {
      dataJson {
        email
        phoneNumbers {
          value
          label
        }
        vat
        pec
        operationalHeadquarters
      }
    }
  `)
  const contacts = data.dataJson

  const [state, setState] = useState({
    loading: false,
    error: null,
    done: false,
    dismissed: true,
  })

  const classes = useStyles()
  const { control, errors, handleSubmit, reset } = useForm<IFormInput>()
  const { executeRecaptcha } = useGoogleReCaptcha()
  const onSubmit = async (data: IFormInput) => {
    setState({ loading: true, error: null, done: false, dismissed: false })
    console.log("Sending form data", data)
    try {
      const recaptcha = await executeRecaptcha("contact_us")
      await doRequest({ ...data, recaptcha })
      setState({ loading: false, error: null, done: true, dismissed: false })
      reset({
        email: "",
        fullname: "",
        phone: "",
        company: "",
        message: "",
        privacy: false,
      })
    } catch (error) {
      console.error(error)
      setState({ loading: false, error, done: false, dismissed: false })
    }
  }

  let dialogState: any
  let dialogMessage: any
  if (state.loading) {
    dialogState = "loading"
    dialogMessage = "Invio della richiesta"
  } else if (state.done) {
    dialogState = "success"
    dialogMessage = "Richiesta inviata!"
  } else if (state.error) {
    dialogState = "error"
    dialogMessage = "Errore nell'invio della richiesta"
  }

  const handleDialogDismiss = () =>
    setState(prev => ({ ...prev, dismissed: true }))

  return (
    <>
      <ContactUsDialog
        open={!state.dismissed}
        state={dialogState}
        message={dialogMessage}
        handleDismiss={handleDialogDismiss}
      />
      <div className={classes.wrapper} id="contacts">
        <Paper className={classes.contactsPaper}>
          <Typography
            variant="h3"
            className={clsx(classes.contactParagraph, classes.contactsTitle)}
          >
            <b>Contatti</b>
          </Typography>
          <Typography component="div" className={classes.contactParagraph}>
            <Typography variant="h4" className={classes.contactTitle}>
              <b>Sede</b>
            </Typography>
            {contacts.operationalHeadquarters}
          </Typography>
          <Typography component="div" className={classes.contactParagraph}>
            <Typography variant="h4" className={classes.contactTitle}>
              <b>Telefono</b>
            </Typography>
            {contacts.phoneNumbers.map(({ value, label }, i) => (
              <Fragment key={i}>
                <MuiLink
                  href={`tel:${value}`}
                  target="_blank"
                  rel="nofollow"
                  className={classes.contactsLink}
                >
                  {label}
                </MuiLink>{" "}
                {i + 1 !== contacts.phoneNumbers.length && <br />}
              </Fragment>
            ))}
          </Typography>
          <Typography component="div" className={classes.contactParagraph}>
            <Typography variant="h4" className={classes.contactTitle}>
              <b>Email</b>
            </Typography>
            <MuiLink
              href={`mailto:${contacts.email}`}
              target="_blank"
              rel="nofollow"
              className={classes.contactsLink}
            >
              {contacts.email}
            </MuiLink>
          </Typography>
        </Paper>
        <form className={classes.formWrapper} onSubmit={handleSubmit(onSubmit)}>
          <Typography
            color="primary"
            variant="h3"
            className={classes.formTitle}
          >
            <b>Chiedi informazioni</b>
          </Typography>
          <Controller
            control={control}
            name="fullname"
            rules={{ required: true }}
            defaultValue=""
            render={({ onChange, onBlur, value }) => (
              <MyTextField
                className={classes.input}
                onBlur={onBlur}
                id="form-fullname"
                onChange={e => onChange(e.target.value)}
                value={value}
                color="primary"
                label="Nome e cognome"
                variant="filled"
                error={!!errors.fullname}
                helperText={
                  errors.fullname?.type === "required"
                    ? "Campo obbligatiorio"
                    : errors.fullname?.message
                }
              />
            )}
          />
          <Controller
            control={control}
            name="phone"
            rules={{ required: true }}
            defaultValue=""
            render={({ onChange, onBlur, value }) => (
              <MyTextField
                className={classes.input}
                onBlur={onBlur}
                id="form-phone"
                onChange={e => onChange(e.target.value)}
                value={value}
                color="primary"
                label="Telefono"
                variant="filled"
                error={!!errors.phone}
                helperText={
                  errors.phone?.type === "required"
                    ? "Campo obbligatiorio"
                    : errors.phone?.message
                }
              />
            )}
          />
          <Controller
            control={control}
            name="email"
            rules={{
              required: true,
              pattern: /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/,
            }}
            defaultValue=""
            render={({ onChange, onBlur, value }) => (
              <MyTextField
                className={classes.input}
                onBlur={onBlur}
                id="form-email"
                onChange={e => onChange(e.target.value)}
                value={value}
                color="primary"
                label="Email"
                variant="filled"
                error={!!errors.email}
                helperText={
                  (errors.email?.type === "required" &&
                    "Campo obbligatiorio") ||
                  (errors.email?.type === "pattern" &&
                    "Il formato non è valido") ||
                  errors.email?.message
                }
              />
            )}
          />
          <Controller
            control={control}
            name="company"
            defaultValue=""
            render={({ onChange, onBlur, value }) => (
              <MyTextField
                className={classes.input}
                onBlur={onBlur}
                id="form-company"
                onChange={e => onChange(e.target.value)}
                value={value}
                color="primary"
                label="Azienda (opzionale)"
                variant="filled"
                error={!!errors.company}
                helperText={
                  errors.company?.type === "required"
                    ? "Campo obbligatiorio"
                    : errors.company?.message
                }
              />
            )}
          />
          <Controller
            control={control}
            name="message"
            rules={{ required: true }}
            defaultValue=""
            render={({ onChange, onBlur, value }) => (
              <MyTextField
                className={classes.msgInput}
                onBlur={onBlur}
                id="form-text"
                onChange={e => onChange(e.target.value)}
                value={value}
                color="primary"
                label="Messaggio"
                variant="filled"
                multiline={true}
                rows={7}
                error={!!errors.message}
                helperText={
                  errors.message?.type === "required"
                    ? "Campo obbligatiorio"
                    : errors.message?.message
                }
              />
            )}
          />
          <FormGroup className={classes.checkBox}>
            <FormControlLabel
              control={
                <Controller
                  control={control}
                  name="privacy"
                  rules={{ required: true }}
                  defaultValue={false}
                  render={({ onChange, onBlur, value }) => (
                    <Checkbox
                      onBlur={onBlur}
                      onChange={e => onChange(e.target.checked)}
                      checked={value}
                      color="primary"
                    />
                  )}
                />
              }
              label={
                <Typography variant="body1" color="textPrimary">
                  Utilizzeremo i tuoi dati al solo scopo di fornirti le
                  informazioni richieste e puoi richiederne la completa
                  cancellazione in qualsiasi momento. Se sei d'accordo, spunta
                  questa casella
                </Typography>
              }
            />
            <FormHelperText error={errors.privacy?.type === "required"}>
              {errors.privacy?.type === "required" && "Campo obbligatiorio"}
            </FormHelperText>
          </FormGroup>
          <Button
            variant="contained"
            size="large"
            color="primary"
            className={classes.submitButton}
            disabled={state.loading}
            type="submit"
            startIcon={<SendOutlined />}
          >
            Invia richiesta
          </Button>
        </form>
      </div>
    </>
  )
}

export const ContactUs: FC = () => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey="6LfP_bMZAAAAAKDP12RUQprl03vNsJYNOe3joAM7">
      <ContactUsForm />
    </GoogleReCaptchaProvider>
  )
}
