import React, { FC } from "react"
import { Paper, makeStyles, Typography } from "@material-ui/core"
import clsx from "clsx"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const useStyles = makeStyles(theme => ({
  wrapper: {
    [theme.breakpoints.up("lg")]: {
      padding: theme.spacing(0, 10),
    },
    [theme.breakpoints.only("md")]: {
      padding: theme.spacing(0, 5),
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(6),
    },
    marginBottom: theme.spacing(12),
    display: "flex",
    flexDirection: "column",
  },
  paper: {
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
    display: "flex",
    width: "100%",
    minHeight: 430,
  },
  boxItem: {
    flexBasis: "33.3333%",
    display: "flex",
    flexDirection: "column",
    color: "white",
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(3, 4),
    },
    padding: theme.spacing(3, 2),
    textAlign: "center",
  },
  punctuality: {
    // backgroundColor: "#6bcbe0",
    background:
      "linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.2)), #6bcbe0",
  },
  reliability: {
    // backgroundColor: "rgba(98, 182, 230, 1)",
    background:
      "linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.2)), #62b6e6",
  },
  quality: {
    // backgroundColor: "#4576b8",
    background:
      "linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.2)), #4576b8",
  },
  title: {
    textAlign: "center",
    marginBottom: theme.spacing(4),
  },
  boxItemTitle: {
    textTransform: "uppercase",
    marginTop: theme.spacing(3),
  },
  icon: {
    flexBasis: "50%",
    maxHeight: 170,
  },
}))

const iconStyle = {
  maxHeight: "100%",
  maxWidth: "100%",
  height: "unset",
  width: "unset",
  left: "50%",
  top: "50%",
  transform: "translate(-50%, -50%)",
}

export const WhyUS: FC = () => {
  const data = useStaticQuery(graphql`
    query {
      punctualityIcon: file(
        relativePath: { eq: "home/whyUs/punctualityIcon.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 200, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      reliabilityIcon: file(
        relativePath: { eq: "home/whyUs/reliabilityIcon.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 200, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      qualityIcon: file(relativePath: { eq: "home/whyUs/qualityIcon.png" }) {
        childImageSharp {
          fluid(maxWidth: 200, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  const classes = useStyles()
  return (
    <div className={classes.wrapper} id="why-us">
      <Typography color="primary" variant="h3" className={classes.title}>
        <b>Perché Vezzaro</b>
      </Typography>
      <Paper className={classes.paper}>
        <div className={clsx(classes.boxItem, classes.punctuality)}>
          <Img
            className={classes.icon}
            fluid={data.punctualityIcon.childImageSharp.fluid}
            title="puntualità"
            alt="puntualità"
            imgStyle={iconStyle}
          />
          <Typography
            variant="h5"
            className={classes.boxItemTitle}
            paragraph={true}
          >
            <b>Puntualità</b> e consegna garantita in <b>24 ore</b>
          </Typography>
          <Typography variant="body1">
            Garantiamo prelievo e consegna in 24 ore, rispettando gli orari
            pattuiti con il cliente, senza ritardi che potrebbero inficiare la
            qualità della merce
          </Typography>
        </div>
        <div className={clsx(classes.boxItem, classes.reliability)}>
          <Img
            className={classes.icon}
            fluid={data.reliabilityIcon.childImageSharp.fluid}
            title="affidabilità"
            alt="affidabilità"
            imgStyle={iconStyle}
          />
          <Typography
            variant="h5"
            className={classes.boxItemTitle}
            paragraph={true}
          >
            <b>Affidabilità</b> e contatto con i titolari
          </Typography>
          <Typography variant="body1">
            Il contatto diretto con i titolari garantisce la massima
            collaborazione e personalizzazione del servizio, andando incontro
            alle specifiche esigenze per volumi, modalità e destinazioni
          </Typography>
        </div>
        <div className={clsx(classes.boxItem, classes.quality)}>
          <Img
            className={classes.icon}
            fluid={data.qualityIcon.childImageSharp.fluid}
            title="precisione"
            alt="precisione"
            imgStyle={iconStyle}
          />
          <Typography
            variant="h5"
            className={classes.boxItemTitle}
            paragraph={true}
          >
            <b>Qualità</b> del trasporto e della consegna
          </Typography>
          <Typography variant="body1">
            Garantiamo il rispetto della catena del freddo e l’assoluta
            integrità della merce, dalla presa in carico alla consegna
          </Typography>
        </div>
      </Paper>
    </div>
  )
}
