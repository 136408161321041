import React, { FC } from "react"
import { useStaticQuery, graphql } from "gatsby"
import {
  Paper,
  Typography,
  useTheme,
  makeStyles,
  Divider,
  Box,
  useMediaQuery,
} from "@material-ui/core"
import { Rating } from "@material-ui/lab"
import { InfiniteCarousel } from "./carousel"
import Img from "gatsby-image"
import BackgroundImage from "gatsby-background-image"

const useStyles = makeStyles(theme => ({
  carousel: {
    [theme.breakpoints.up("md")]: {
      margin: theme.spacing(10),
      marginRight: "auto",
    },
    margin: `${theme.spacing(5)}px auto`,
    maxWidth: 640,
  },
  slide: {
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(5),
      paddingBottom: theme.spacing(4) + 50,
    },
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "auto",
      gridTemplateRows: "auto auto auto auto auto 1fr",
      gridTemplateAreas: `"logo"
      "partner"
      "rate"
      "divider"
      "title"
      "review"`,
    },
    minHeight: "100%",
    padding: theme.spacing(2),
    display: "grid",
    gridTemplateColumns: "130px 1fr",
    gridColumnGap: 0,
    gridTemplateRows: "auto auto auto auto 1fr",
    gridTemplateAreas: `"logo partner"
    "logo rate"
    "divider divider"
    "title title"
    "review review"`,
  },
  title: {
    marginBottom: theme.spacing(2),
    textAlign: "left",
    gridArea: "title",
  },
  rate: {
    [theme.breakpoints.down("xs")]: {
      marginLeft: "auto",
      marginRight: "auto",
    },
    gridArea: "rate",
    color: theme.palette.primary.light,
    marginLeft: theme.spacing(2),
    fontSize: "2.1rem",
  },
  review: {
    whiteSpace: "pre-wrap",
    gridArea: "review",
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
  divider: {
    margin: theme.spacing(3, 0),
    gridArea: "divider",
  },
  logo: {
    gridArea: "logo",
    overflow: "hidden",
    height: 55,
  },
  partner: {
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
    marginLeft: theme.spacing(2),
    gridArea: "partner",
    textAlign: "left",
  },
}))

export const PartnerReview: FC = () => {
  const classes = useStyles()
  const data = useStaticQuery(graphql`
    query PartnerReviewes {
      allFile(
        filter: {
          extension: { regex: "/(jpg)|(png)|(tif)|(tiff)|(webp)|(jpeg)/" }
          relativePath: { regex: "/home/partner/logo/" }
        }
      ) {
        edges {
          node {
            childImageSharp {
              fixed(width: 130, height: 55, quality: 80) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
            name
          }
        }
      }
      allPartnerReviewJson {
        edges {
          node {
            address
            logoName
            name
            rate
            review
            title
          }
        }
      }
      desktopBackground: file(
        relativePath: { eq: "home/partner/background/background.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mobileBackground: file(
        relativePath: { eq: "home/partner/background/background.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"))
  const reviews = data.allPartnerReviewJson.edges.map(({ node: revNode }) => {
    const logoNode = data.allFile.edges.find(
      ({ node: logoNode }) => logoNode.name === revNode.logoName
    )
    return {
      logoFixedSource: logoNode.node.childImageSharp.fixed,
      name: revNode.name,
      address: revNode.address,
      logoName: revNode.logoName,
      rate: revNode.rate,
      title: revNode.title,
      review: revNode.review,
    }
  })

  const backbroundSources = [
    data.desktopBackground.childImageSharp.fluid,
    {
      ...data.mobileBackground.childImageSharp.fluid,
      media: theme.breakpoints.down("sm"),
    },
  ]
  return (
    <BackgroundImage
      Tag="section"
      className={"className"}
      fluid={backbroundSources}
      backgroundColor={`#040e18`}
    >
      <InfiniteCarousel
        arrowPrevStyle={{
          right: `calc(${theme.spacing(5)}px + 50px)`,
        }}
        arrowNextStyle={{
          right: theme.spacing(5),
        }}
        showIndicator={false}
        showArrows="desktop"
        className={classes.carousel}
      >
        {reviews.map((s, i) => {
          return (
            <Paper key={`slide${i}`} className={classes.slide}>
              <Box
                borderRadius="50%"
                borderColor="grey.500"
                className={classes.logo}
              >
                <Img
                  durationFadeIn={50}
                  title={`logo ${s.name} - vezzaro snc partner`}
                  alt={`${s.name} partner logo`}
                  fixed={s.logoFixedSource}
                />
              </Box>
              <Typography variant="body1" className={classes.partner}>
                <b>{s.name}</b>
                {isDesktop ? <>,&nbsp;</> : <br />}
                {s.address}
              </Typography>
              <Rating
                name="rating recensione"
                defaultValue={s.rate}
                size="large"
                readOnly={true}
                className={classes.rate}
              />
              <Divider className={classes.divider} />
              <Typography variant="h6" className={classes.title}>
                {s.title}
              </Typography>
              <Typography variant="body2" className={classes.review}>
                {s.review}
              </Typography>
            </Paper>
          )
        })}
      </InfiniteCarousel>
      <Divider />
    </BackgroundImage>
  )
}
