import React, { FC } from "react"
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  CircularProgress,
  makeStyles,
  Theme,
  createStyles,
} from "@material-ui/core"
import { DoneOutlineOutlined } from "@material-ui/icons"
import clsx from "clsx"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      fontSize: 100,
      margin: "auto",
      display: "flex",
      flexDirection: "column",
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(3)
    },
    success: {
      color: theme.palette.success.main,
    },
    message: {
      textAlign: "center",
    },
  })
)

interface IProps {
  open: boolean
  state: "error" | "loading" | "success"
  message: string
  handleDismiss: () => void
}

export const ContactUsDialog: FC<IProps> = ({
  open,
  state,
  message,
  handleDismiss,
}) => {
  const classes = useStyles()
  return (
    <Dialog
      open={open}
      onClose={handleDismiss}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
      maxWidth="sm"
    >
      <DialogContent>
        {state === "loading" && (
          <CircularProgress
            className={classes.icon}
            size={150}
            color="secondary"
          />
        )}
        {state === "success" && (
          <DoneOutlineOutlined
            className={clsx(classes.icon, classes.success)}
          />
        )}
        {state === "error" && (
          <DoneOutlineOutlined className={classes.icon} color="error" />
        )}
        <DialogContentText
          id="alert-dialog-description"
          className={classes.message}
        >
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDismiss} color="primary" autoFocus>
          Torna al sito
        </Button>
      </DialogActions>
    </Dialog>
  )
}
