import {
  makeStyles,
  useTheme,
  Typography,
  useMediaQuery,
} from "@material-ui/core"
import React, { FC } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const useStyles = makeStyles(theme => ({
  wrapper: {
    [theme.breakpoints.up("lg")]: {
      padding: theme.spacing(0, 10),
    },
    [theme.breakpoints.between("sm", "lg")]: {
      padding: theme.spacing(0, 5),
    },
    [theme.breakpoints.up("md")]: {
      display: "grid",
      gridTemplateColumns: "auto 50%",
      gridColumnGap: theme.spacing(6),
      gridTemplateRows: "auto auto 1fr",
      gridTemplateAreas: `"title title"
      "image mainText"
      "image text"`,
    },
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0, 2),
      marginBottom: theme.spacing(6),
      display: "grid",
      gridTemplateColumns: "auto",
      gridColumnGap: 0,
      gridTemplateRows: "auto auto 1fr auto",
      gridTemplateAreas: `"title"
      "mainText"
      "text"
      "image"`,
    },
    marginBottom: theme.spacing(12),
    position: "relative",
  },
  imageWrapper: {
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(2),
    },
    gridArea: "image",
    paddingBottom: "calc(5/8 * 100%)",
    position: "relative",
  },
  mainText: {
    gridArea: "mainText",
    margin: theme.spacing(2, 0),
  },
  text: {
    gridArea: "text",
    color: theme.palette.text.secondary,
  },
  image: {
    maxHeight: "100%",
    margin: "auto 0",
    width: "100%",
    "&::before": {
      content: `""`,
      position: "absolute",
      width: "100%",
      height: "100%",
      top: 20,
      left: -10,
      background: `linear-gradient(to bottom, ${theme.palette.secondary.main} 0, ${theme.palette.secondary.main} 100%) no-repeat`,
    },
  },
  imageRatioKeeper: {
    position: "absolute",
    width: "100%",
    height: "100%",
    display: "flex",
  },
  title: {
    flexBasis: "100%",
    textAlign: "center",
    marginBottom: theme.spacing(4),
    gridArea: "title",
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(1),
    },
  },
}))

export const WhoWeAre: FC = () => {
  const data = useStaticQuery(graphql`
    query {
      desktopImage: file(relativePath: { eq: "home/whoWeAre/we.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mobileImage: file(relativePath: { eq: "home/whoWeAre/we.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  const classes = useStyles()
  const theme = useTheme()

  const sources = [
    data.desktopImage.childImageSharp.fluid,
    {
      ...data.mobileImage.childImageSharp.fluid,
      media: theme.breakpoints.down("sm"),
    },
  ]

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  return (
    <div className={classes.wrapper} id="who-we-are">
      <Typography color="primary" variant="h3" className={classes.title}>
        <b>Chi siamo</b>
      </Typography>
      <div className={classes.imageWrapper}>
        <div className={classes.imageRatioKeeper}>
          <Img
            style={{ overflow: "unset" }}
            className={classes.image}
            fluid={sources}
            title="chi siamo"
            alt="chi siamo"
          />
        </div>
      </div>
      <Typography variant={isMobile ? "h5" : "h4"} className={classes.mainText}>
        Dal 1993 siamo professionisti del trasporto di piccoli e medi carichi di
        fresco deperibile refrigerato, surgelato e a temperatura ambiente
      </Typography>
      <div className={classes.text}>
        <Typography variant="body1" component="p" paragraph={true}>
          La nostra è una piccola azienda a livello familiare, ma il rapporto
          diretto con i titolari è garanzia di affidabilità.
          <br />
          Serietà e precisione sono da sempre il nostro punto di forza.
        </Typography>
        <Typography variant="body1" component="p" paragraph={true}>
          Nel tempo abbiamo saputo modificare le nostre strategie, adeguandoci al presente ma con lo sguardo sempre rivolto al futuro.
          <br />
          L'unico obiettivo è soddisfare i nostri clienti.
        </Typography>
        <Typography variant="body1" component="p" paragraph={true}>
          Mettiamo passione e cura nel trasporto, così come nelle persone.
        </Typography>
      </div>
    </div>
  )
}
