import React, { FC, CSSProperties, ReactElement } from "react"
import { Carousel } from "react-responsive-carousel"
import "react-responsive-carousel/lib/styles/carousel.min.css" // requires a loader
import "./carousel.css"
import { Fab, useMediaQuery, useTheme } from "@material-ui/core"
import { NavigateNext, NavigateBefore } from "@material-ui/icons"

const arrowStyles: CSSProperties = {
  position: "absolute",
  zIndex: 2,
  bottom: "25px",
  cursor: "pointer",
  backgroundColor: "white"
}

interface IInfiniteCarousel {
  children?: React.ReactChild[]
  arrowNextStyle: CSSProperties
  arrowPrevStyle: CSSProperties
  showArrows?: "always" | "desktop" | "never"
  showIndicator?: boolean
  className?: string
}

export const InfiniteCarousel: FC<IInfiniteCarousel> = ({
  children,
  arrowNextStyle,
  arrowPrevStyle,
  showArrows,
  showIndicator,
  className,
}) => {
  const theme = useTheme()
  let shouldShowArrows: boolean | undefined
  if (!showArrows || showArrows === "always") shouldShowArrows = true
  else if (showArrows === "never") shouldShowArrows = false
  else if (showArrows === "desktop") {
    if (useMediaQuery(theme.breakpoints.up("sm"))) {
      shouldShowArrows = true
    } else {
      shouldShowArrows = false
    }
  }
  return (
    <Carousel
      className={className}
      showThumbs={false}
      showStatus={false}
      infiniteLoop={true}
      showIndicators={showIndicator}
      autoPlay={true}
      swipeScrollTolerance={1}
      transitionTime={500}
      interval={3500}
      
      renderArrowPrev={(onClickHandler, _, label) =>
        shouldShowArrows && (
          <Fab
            onClick={onClickHandler}
            title={label}
            style={{ ...arrowStyles, ...arrowPrevStyle }}
            size="small"
          >
            <NavigateBefore />
          </Fab>
        )
      }
      renderArrowNext={(onClickHandler, _, label) =>
        shouldShowArrows && (
          <Fab
            onClick={onClickHandler}
            title={label}
            style={{ ...arrowStyles, ...arrowNextStyle }}
            size="small"
          >
            <NavigateNext />
          </Fab>
        )
      }
    >
      {children}
    </Carousel>
  )
}
