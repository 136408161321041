import React, { FC } from "react"
import {
  makeStyles,
  Typography,
  useTheme,
  Paper,
  Divider,
  useMediaQuery,
} from "@material-ui/core"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const useStyles = makeStyles(theme => ({
  wrapper: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
    },
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(0, 5, 5, 5),
    },
    [theme.breakpoints.up("md")]: {
      display: "grid",
      gridTemplateColumns: "55% 45%",
      gridColumnGap: 0,
      gridTemplateRows: "auto auto",
      gridTemplateAreas: `"text images"
      "experience images"`,
      padding: theme.spacing(0, 10, 10, 10),
    },
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      flexDirection: "column",
    },
  },
  textWrapper: {
    gridArea: "text",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(4),
    },
    padding: theme.spacing(10, 5, 0, 0),
  },
  images: {
    [theme.breakpoints.up("sm")]: {
      "& > :first-child": {
        marginBottom: theme.spacing(1),
      },
      "& > :last-child": {
        marginTop: theme.spacing(1),
      },
    },
    gridArea: "images",
    display: "flex",
    flexDirection: "column",
  },
  image: {
    height: `calc(50% - ${theme.spacing(1)}px)`,
    width: "100%",
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  experiencePaper: {
    [theme.breakpoints.down("sm")]: {
      margin: 0,
      minWidth: 300,
    },
    [theme.breakpoints.between("sm", "md")]: {
      margin: theme.spacing(0, 0, 5, 0),
    },
    position: "relative",
    gridArea: "experience",
    margin: `${theme.spacing(5)}px -10% 100px 0`,
    height: 180 - theme.spacing(8),
    zIndex: 1000,
    padding: theme.spacing(4, 0),
    minWidth: 680,
    display: "grid",
    gridTemplateColumns: "33% auto 33% auto 33%",
    gridColumnGap: 0,
    gridTemplateRows: "auto auto",
    textAlign: "center",
  },
  expQta: {
    margin: "auto",
    marginBottom: 0,
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(0, 2),
    },
    padding: theme.spacing(0, 4),
    color: theme.palette.secondary.dark,
  },
  expDesc: {
    margin: "auto",
    marginTop: 0,
    textTransform: "uppercase",
    color: theme.palette.text.secondary,
  },
  paragraph: {
    color: theme.palette.text.secondary,
  },
  slogan: {
    marginTop: theme.spacing(3),
    textAlign: "center",
  },
}))

export const MainContent: FC = () => {
  const data = useStaticQuery(graphql`
    query MainContentImages {
      desktopFirstImg: file(
        relativePath: { eq: "home/main/trasporto_freschi.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 80, maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
        name
      }
      desktopSecondImg: file(
        relativePath: { eq: "home/main/trasporto_collettame.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 80, maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
        name
      }
      mobileImg: file(relativePath: { eq: "home/main/trasporto_freschi.jpg" }) {
        childImageSharp {
          fluid(quality: 80, maxWidth: 400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
        name
      }
    }
  `)
  const classes = useStyles()
  const theme = useTheme()
  const firstImg = {
    sources: [
      data.desktopFirstImg.childImageSharp.fluid,
      {
        ...data.mobileImg.childImageSharp.fluid,
        media: theme.breakpoints.down("sm"),
      },
    ],
    name: data.desktopFirstImg.name,
  }
  const secondImg = {
    sources: data.desktopSecondImg.childImageSharp.fluid,
    name: data.desktopSecondImg.name,
  }
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
  const isSmallMobile = useMediaQuery(theme.breakpoints.down("sm"))
  return (
    <div className={classes.wrapper}>
      <div className={classes.textWrapper}>
        <Typography variant="h4" className={classes.title}>
          Da oltre 25 anni esperienza e competenza nel trasporto di prodotti
          alimentari freschi e refrigerati
        </Typography>

        <Typography
          variant="body1"
          paragraph={true}
          className={classes.paragraph}
        >
          Per i prodotti alimentari mantenere la giusta temperatura significa
          garantire proprietà organolettiche e qualità.
        </Typography>
        <Typography
          variant="body1"
          paragraph={true}
          className={classes.paragraph}
        >
          Siamo specializzati nel trasporto di{" "}
          <Typography component="span" color="primary">
            <b>collettame e pallet</b>
          </Typography>{" "}
          per la piccola e media distribuzione.
        </Typography>
        <Typography variant="body1" className={classes.paragraph}>
          Trasportiamo sempre alla temperatura ottimale: dal{" "}
          <Typography component="span" color="primary">
            <b>fresco deperibile</b>
          </Typography>{" "}
          al surgelato, ma anche secco.
        </Typography>
        <Typography
          variant="body1"
          paragraph={true}
          className={classes.paragraph}
        >
          Le temperature di trasporto sono costantemente{" "}
          <Typography component="span" color="primary">
            <b>monitorate</b>
          </Typography>
          , da -18 a temperatura ambiente, al fine di mantenere la catena del
          freddo.
        </Typography>
        <Typography
          variant="body1"
          paragraph={true}
          className={classes.paragraph}
        >
          I nostri furgoni sono dotati di paratia mobile per il{" "}
          <Typography component="span" color="primary">
            <b>trasporto a doppia temperatura</b>
          </Typography>
          , permettendoci di ottenere la massima{" "}
          <Typography component="span" color="primary">
            <b>versatilità</b>
          </Typography>{" "}
          possibile del servizio.
        </Typography>
        <Typography variant="body1" className={classes.paragraph}>
          I nostri autisti sono cresciuti con noi,{" "}
          <Typography component="span" color="primary">
            <b>disponibilità</b>
          </Typography>{" "}
          e{" "}
          <Typography component="span" color="primary">
            <b>cortesia</b>
          </Typography>{" "}
          sono le qualità che ci contraddistinguono.
        </Typography>
        <Typography
          variant="body1"
          paragraph={true}
          className={classes.paragraph}
        >
          Il trasporto è{" "}
          <Typography component="span" color="primary">
            <b>flessibile e personalizzabile</b>
          </Typography>
          : la consegna della merce è assicurata entro 24 ore dal ritiro, nel
          pieno rispetto delle esigenze e caratteristiche delle varie tipologie.
        </Typography>
        <Typography
          variant="body1"
          paragraph={true}
          className={classes.paragraph}
        >
          Inoltre,{" "}
          <Typography component="span" color="primary">
            <b>serietà</b>
          </Typography>{" "}
          e{" "}
          <Typography component="span" color="primary">
            <b>precisione</b>
          </Typography>{" "}
          sono da sempre i nostri punti di forza e ci hanno permesso di
          consolidare collaborazioni durature con importanti aziende alimentari.{" "}
        </Typography>
        <Typography
          variant="h5"
          paragraph={true}
          color="primary"
          className={classes.slogan}
        >
          <b>CARICATO... GIÀ CONSEGNATO!</b>
        </Typography>
      </div>

      <Paper className={classes.experiencePaper}>
        <Typography
          component="span"
          variant="h3"
          style={{ gridArea: "1 / 1" }}
          className={classes.expQta}
        >
          <b>25+</b>
        </Typography>
        <Typography
          component="span"
          variant={isSmallMobile ? "body2" : "body1"}
          style={{ gridArea: "2 / 1" }}
          className={classes.expDesc}
        >
          Anni di esperienza
        </Typography>
        <Divider
          orientation="vertical"
          style={{ gridArea: "1 / 2 / span 2 / 2" }}
        />
        <Typography
          component="span"
          variant="h3"
          style={{ gridArea: "1 / 3" }}
          className={classes.expQta}
        >
          <b>200k+</b>
        </Typography>
        <Typography
          component="span"
          variant={isSmallMobile ? "body2" : "body1"}
          style={{ gridArea: "2 / 3" }}
          className={classes.expDesc}
        >
          Consegne effettuate
        </Typography>
        <Divider
          orientation="vertical"
          style={{ gridArea: "1 / 4 / span 2 / 4" }}
        />
        <Typography
          component="span"
          variant="h3"
          style={{ gridArea: "1 / 5" }}
          className={classes.expQta}
        >
          <b>17M+</b>
        </Typography>
        <Typography
          component="span"
          variant={isSmallMobile ? "body2" : "body1"}
          style={{ gridArea: "2 / 5" }}
          className={classes.expDesc}
        >
          Kilometri percorsi
        </Typography>
      </Paper>
      <div className={classes.images}>
        <Img
          durationFadeIn={50}
          title={firstImg.name.replace("_", " ")}
          alt={firstImg.name}
          fluid={firstImg.sources}
          className={classes.image}
        />
        {!isMobile && (
          <Img
            durationFadeIn={50}
            title={secondImg.name.replace("_", " ")}
            alt={secondImg.name}
            fluid={secondImg.sources}
            className={classes.image}
          />
        )}
      </div>
    </div>
  )
}
