import React, { FC } from "react"
import { Layout } from "../components/layout"
import { SEO } from "../components/seo"
import { HomeGallery } from "../components/homeGallery"
import { PartnerLogoGallery } from "../components/partnerLogoGallery"
import { PartnerReview } from "../components/partnerReview"
import { MainContent } from "../components/mainContent"
import { Where } from "../components/where"
import { WhyUS } from "../components/whyUs"
import { WhoWeAre } from "../components/whoWeAre"
import { ContactUs } from "../components/contactUs"

const IndexPage: FC = () => {
  return (
    <Layout>
      <SEO title="Home" />
      <HomeGallery />
      <MainContent />
      <Where />
      <WhyUS />
      <WhoWeAre />
      <PartnerLogoGallery />
      <PartnerReview />
      <ContactUs />
    </Layout>
  )
}

export default IndexPage
